import React, { useContext, useEffect, useState, useCallback } from "react";
import { useIsMounted } from "../hooks/useIsMounted";
const ScreenContext = React.createContext();

const getWindowWidth = () => {
  const { innerWidth: width } = window;
  return width;
};

const getWindowHeight = () => {
  const { innerHeight: height } = window;
  return height;
};

export function useScreen() {
  return useContext(ScreenContext);
}

export function ScreenProvider({ children }) {
  const initialScreenState = {
    width: getWindowWidth(),
    height: getWindowHeight(),
  };
  const [screenDimensions, setScreenDimensions] = useState(initialScreenState);
  const [windowYOffset, setWindowYOffset] = useState(window.pageYOffset);
  const [isTop, setIsTop] = useState(true);
  let isMounted = useIsMounted();

  const handleResize = useCallback(async () => {
    await sleep(250);
    const screenDimensions_ = { ...screenDimensions };
    screenDimensions_["height"] = getWindowHeight();
    screenDimensions_["width"] = getWindowWidth();
    if (!isMounted.current) return;
    setScreenDimensions(screenDimensions_);
  }, [screenDimensions]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = useCallback(() => {
    if (!isMounted.current) return;
    setWindowYOffset(window.pageYOffset);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("resize", handleResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted.current) {
      setStyleDimensions();
    }
  }, [screenDimensions]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isMounted.current) {
      windowYOffset === 0 ? setIsTop(true) : setIsTop(false);
    }
  }, [windowYOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  const setStyleDimensions = () => {
    document.documentElement.style.setProperty(
      "--vw",
      `${screenDimensions.width}px`
    );
    document.documentElement.style.setProperty(
      "--vh",
      `${screenDimensions.height}px`
    );
  };

  const value = {
    screenWidth: screenDimensions.width,
    screenHeight: screenDimensions.height,
    windowYOffset,
    isTop,
  };

  return (
    <ScreenContext.Provider value={value}>{children}</ScreenContext.Provider>
  );
}

async function sleep(msec) {
  return new Promise((resolve) => setTimeout(resolve, msec));
}
