import React, { useState, useEffect, useRef } from "react";
import { useScreen } from "../../context/ScreenProvider";

export default function ScrollSpyAnimate({
  children,
  dependencies, // array of booleans.
  baseDelaySecs,
  classList,
  disableScrollSpy,
}) {
  const [isViewable, setIsViewable] = useState(false);
  const [isAnimated, setIsAnimated] = useState(false);

  const spyRef = useRef(null);

  const screenContext = useScreen();
  const { windowYOffset, screenHeight } = screenContext;

  const evaluateAnimateTrigger = () => {
    let animDeps = [true];

    if (!disableScrollSpy) animDeps = [...animDeps, isViewable];

    if (
      Array.isArray(dependencies) &&
      dependencies.every((d) => typeof d === "boolean")
    ) {
      animDeps = [...animDeps, ...dependencies];
    }

    if (animDeps.every((d) => d === true)) return setIsAnimated(true);
    setIsAnimated(false);
  };

  useEffect(() => {
    if (spyRef.current) {
      let el = spyRef.current.getBoundingClientRect(),
        isInViewport = [
          el.top >= 0 && el.top <= screenHeight,
          el.bottom >= 0 && el.bottom <= screenHeight,
        ];

      setIsViewable(isInViewport.some((i) => i === true));
    }
  }, [windowYOffset]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    evaluateAnimateTrigger();
  }, [isViewable, dependencies, baseDelaySecs]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      ref={spyRef}
      className={`scrollspy ${
        Array.isArray(classList) ? classList.join(" ") : ""
      } ${isAnimated ? "animated" : "hidden"}`}
      style={isAnimated ? { transitionDelay: `${baseDelaySecs}s` } : {}}
    >
      {children}
    </div>
  );
}
