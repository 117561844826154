import React, { useEffect, useState, useRef } from "react";
import GridBGOverlay from "../GridBGOverlay";
import { Icon } from "semantic-ui-react";

export default function ModalWall({ isActive, actionCb, cta }) {
  const [active, setActive] = useState(true);

  const bodyRef = useRef(document.body);

  const delaySwitch = () => {
    setTimeout(() => {
      setActive(isActive);
    }, 1500);
  };

  useEffect(() => {
    delaySwitch();

    if (isActive === true) {
      if (bodyRef?.current) bodyRef.current.style.overflow = "hidden";
    } else {
      if (bodyRef?.current) bodyRef.current.style.overflow = "scroll";
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  if (active === false) return null;
  return (
    <div className={`modalwall ${isActive ? "active" : "inactive"}`}>
      <GridBGOverlay from={"var(--highlight-3)"} to={"var(--highlight-1)"} />

      <div className="modalwall__content">
        <button onClick={() => actionCb()}>
          <div className="pulsating-circle">
            <span>
              <Icon name="play" />
            </span>
          </div>

          <h2>{cta}</h2>
        </button>
      </div>
    </div>
  );
}
