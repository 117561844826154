import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ScreenProvider } from "./context/ScreenProvider";
import ScrollTop from "./hooks/useScrollTop";

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <ScreenProvider>
        <ScrollTop>
          <App />
        </ScrollTop>
      </ScreenProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
