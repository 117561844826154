import React, { useState, useEffect, useRef } from "react";
import ScrollSpyAnimate from "../ScrollSpyAnimate";
import StyledBorder from "../StyledBorder";
import { Icon } from "semantic-ui-react";
import moment from "moment";

export default function StyledLink({
  l,
  i,
  dependencies,
  imgLoadCb,
  disabled,
}) {
  const [timeToUnlock, setTimeToUnlock] = useState(null);
  const [isLocked, setIsLocked] = useState(false);

  let unlockTimer = useRef(null);

  const calculateTimeToUnlock = () => {
    let unlockDate = new moment(l.unlock),
      today = new moment(new Date().toString()),
      dateDelta = moment.duration(unlockDate.diff(today));

    let days = dateDelta.days(),
      hours = dateDelta.hours(),
      minutes = dateDelta.minutes(),
      seconds = dateDelta.seconds();

    let timeString = `${zeroPad(days, 2)}:${zeroPad(hours, 2)}:${zeroPad(
      minutes,
      2
    )}:${zeroPad(seconds, 2)}`;

    setTimeToUnlock(timeString);
  };

  useEffect(() => {
    if (!checkUnlock(timeToUnlock)) {
      setIsLocked(false);
      console.log(isLocked);
    }
  }, [timeToUnlock]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (l?.unlock && !unlockTimer?.current) {
      unlockTimer.current = setInterval(() => {
        calculateTimeToUnlock();
      }, 1000);
    }

    return () => {
      if (unlockTimer?.current) clearInterval(unlockTimer.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {l?.thumbnail && (
        <ScrollSpyAnimate
          dependencies={[...dependencies]}
          classList={["bottom"]}
          baseDelaySecs={0.4 + (i + 1) * 0.2}
        >
          <div style={{ width: "100%" }}>
            <StyledBorder isAlternate={true} classList={["links__thumbnail"]}>
              <div className="img">
                <img
                  onLoad={() => imgLoadCb()}
                  src={l.thumbnail}
                  alt="link_thumb"
                />
              </div>
            </StyledBorder>
          </div>
        </ScrollSpyAnimate>
      )}

      <div
        className={`${!l.thumbnail ? "no-thumb" : ""}`}
        style={
          l.thumbnail ? { width: "100%" } : { width: "90%", margin: "auto" }
        }
      >
        <div
          style={l.thumbnail ? {} : { marginLeft: 0 }}
          className="links__meta"
        >
          <ScrollSpyAnimate
            dependencies={[...dependencies]}
            classList={["bottom"]}
            baseDelaySecs={0.5 + (i + 1) * 0.05}
          >
            <StyledBorder classList={["links__meta__container", "text"]}>
              <div className="links__meta__container__content">
                <h2 style={l?.thumbnail ? {} : { fontSize: 19 }}>
                  {l.heading}
                </h2>
                <p style={l?.thumbnail ? {} : { fontSize: 14 }}>
                  {l?.unlock ? l.unlockDescription : l.description}
                </p>

                {l?.icon && (
                  <>
                    <div className="link__icon">
                      {typeof l.icon === "function" && l.icon()}
                      {typeof l.icon === "string" && <Icon name={l.icon} />}
                    </div>
                  </>
                )}
              </div>
            </StyledBorder>
          </ScrollSpyAnimate>

          <ScrollSpyAnimate
            dependencies={[...dependencies]}
            classList={["right", "links__meta__tag"]}
            baseDelaySecs={0.6 + (i + 1) * 0.2}
          >
            <StyledBorder>
              <p>
                {l?.unlock ? (
                  <>
                    {timeToUnlock}{" "}
                    <Icon name={"clock outline"} style={{ margin: 0 }} />
                  </>
                ) : (
                  <>
                    {l.tag}{" "}
                    <Icon
                      name={!disabled ? "arrow right" : "ban"}
                      style={{ margin: 0 }}
                    />
                  </>
                )}
              </p>
            </StyledBorder>
          </ScrollSpyAnimate>
        </div>
      </div>
    </>
  );
}

function zeroPad(num, numZeros) {
  var n = Math.abs(num);
  var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
  var zeroString = Math.pow(10, zeros).toString().substr(1);
  if (num < 0) {
    zeroString = "-" + zeroString;
  }

  return zeroString + n;
}

function checkUnlock(dateString) {
  if (moment(dateString).isAfter(moment(new Date()))) return dateString;
  return null;
}
