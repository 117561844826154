import React from "react";
import { useScreen } from "../../context/ScreenProvider";

export default function GridBGOverlay({ from, to }) {
  const screenContext = useScreen();
  const { windowYOffset, screenHeight } = screenContext;

  let degCeiling = 20,
    maxDeg = 40,
    degAdjusted = ((windowYOffset + 1) / screenHeight) * degCeiling;

  if (degAdjusted >= maxDeg) degAdjusted = maxDeg;

  return (
    <div
      className={`gridbg`}
      style={{
        background: `linear-gradient(
        360deg,
        ${from} 0%,
        ${to} 100%
      )`,
      }}
    >
      <div
        className="gridbg__grid"
        style={{
          transform: `scale(1.5) perspective(30em) translateY(-10%) rotateX(${degAdjusted}deg)`,
        }}
      ></div>
    </div>
  );
}
