import { Switch, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Woah from "../Pages/Songs/Woah";

export default function Router() {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/woah" exact component={Woah} />
    </Switch>
  );
}
