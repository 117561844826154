import React from "react";

export const Youtube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 26"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
    />
    <path
      className="fill"
      d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
    />
  </svg>
);

export const Instagram = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 26"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
    />
    <path
      className="fill"
      d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"
    />
  </svg>
);

export const AppleMusic = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 26"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 13.804c-.393 1.14-1.569 3.159-2.782 3.18-.804.016-1.062-.476-1.98-.476s-1.207.461-1.967.492c-1.286.048-3.271-2.915-3.271-5.498 0-2.374 1.654-3.55 3.099-3.572.774-.014 1.507.522 1.98.522.475 0 1.364-.645 2.298-.55.391.016 1.488.158 2.195 1.189-1.873 1.221-1.581 3.774.428 4.713zm-2.611-8.804c-1.413.058-2.566 1.54-2.404 2.765 1.304.102 2.558-1.362 2.404-2.765z"
    />
    <path
      className="fill"
      d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5 13.804c-.393 1.14-1.569 3.159-2.782 3.18-.804.016-1.062-.476-1.98-.476s-1.207.461-1.967.492c-1.286.048-3.271-2.915-3.271-5.498 0-2.374 1.654-3.55 3.099-3.572.774-.014 1.507.522 1.98.522.475 0 1.364-.645 2.298-.55.391.016 1.488.158 2.195 1.189-1.873 1.221-1.581 3.774.428 4.713zm-2.611-8.804c-1.413.058-2.566 1.54-2.404 2.765 1.304.102 2.558-1.362 2.404-2.765z"
    />
  </svg>
);

export const Spotify = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 24 26"
    xmlns="http://www.w3.org/2000/svg"
    fillRule="evenodd"
    clipRule="evenodd"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"
    />
    <path
      className="fill"
      d="M19.098 10.638c-3.868-2.297-10.248-2.508-13.941-1.387-.593.18-1.22-.155-1.399-.748-.18-.593.154-1.22.748-1.4 4.239-1.287 11.285-1.038 15.738 1.605.533.317.708 1.005.392 1.538-.316.533-1.005.709-1.538.392zm-.126 3.403c-.272.44-.847.578-1.287.308-3.225-1.982-8.142-2.557-11.958-1.399-.494.15-1.017-.129-1.167-.623-.149-.495.13-1.016.624-1.167 4.358-1.322 9.776-.682 13.48 1.595.44.27.578.847.308 1.286zm-1.469 3.267c-.215.354-.676.465-1.028.249-2.818-1.722-6.365-2.111-10.542-1.157-.402.092-.803-.16-.895-.562-.092-.403.159-.804.562-.896 4.571-1.045 8.492-.595 11.655 1.338.353.215.464.676.248 1.028zm-5.503-17.308c-6.627 0-12 5.373-12 12 0 6.628 5.373 12 12 12 6.628 0 12-5.372 12-12 0-6.627-5.372-12-12-12z"
    />
  </svg>
);

export const Twitter = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 26"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
    <path
      className="fill"
      d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"
    />
  </svg>
);

export const TikTok = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 26"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M16.6,0.1c0.4,3.4,2.3,5.4,5.6,5.6v3.8c-1.9,0.2-3.6-0.4-5.5-1.6V15c0,9-9.8,11.8-13.8,5.4
	c-2.5-4.2-1-11.5,7.2-11.8v4c-0.6,0.1-1.3,0.3-1.9,0.5c-1.8,0.6-2.8,1.8-2.6,3.8c0.5,3.9,7.7,5,7.1-2.6V0.1L16.6,0.1L16.6,0.1z"
    />

    <path
      className="fill"
      d="M16.6,0.1c0.4,3.4,2.3,5.4,5.6,5.6v3.8c-1.9,0.2-3.6-0.4-5.5-1.6V15c0,9-9.8,11.8-13.8,5.4
	c-2.5-4.2-1-11.5,7.2-11.8v4c-0.6,0.1-1.3,0.3-1.9,0.5c-1.8,0.6-2.8,1.8-2.6,3.8c0.5,3.9,7.7,5,7.1-2.6V0.1L16.6,0.1L16.6,0.1z"
    />
  </svg>
);

export const Mail = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 24 26"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"
    />
    <path
      className="fill"
      d="M0 3v18h24v-18h-24zm21.518 2l-9.518 7.713-9.518-7.713h19.036zm-19.518 14v-11.817l10 8.104 10-8.104v11.817h-20z"
    />
  </svg>
);

export const Location = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 30"
    className="styled__icon"
  >
    <path
      className="shadow"
      d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
    />
    <path
      className="fill"
      d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
    />
  </svg>
);

export const TBCoin = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 300 300"
  >
    <path
      d="M150,50.4C95,50.4,50.4,95,50.4,150c0,13.6,2.7,26.6,7.7,38.4v0c0,0,0,0,0,0c0.6,1.4,1.2,2.7,1.8,4c0,0.1,0,0.1,0.1,0.2
		c0.6,1.3,1.3,2.6,1.9,3.9c0,0.1,0.1,0.1,0.1,0.2c0.7,1.3,1.4,2.5,2.1,3.7c0,0.1,0.1,0.1,0.1,0.2c0.7,1.2,1.5,2.5,2.3,3.7
		c0,0,0,0.1,0.1,0.1c3.3,5,6.9,9.7,11,14c0,0,0,0,0,0v0c18.2,19.3,43.9,31.3,72.5,31.3c55,0,99.6-44.6,99.6-99.6
		C249.6,95,205,50.4,150,50.4z M150,238.6c-26.5,0-50.3-11.7-66.5-30.1c0.9-1.6,1.8-3.2,2.8-4.8c4.4-7.6,8.8-15.3,13.2-22.9
		c5.1-8.8,10.1-17.6,15.2-26.3c4.3-7.5,8.7-15,13-22.5c1.5-2.5,2.9-5,4.4-7.6c2.3-4,4.3-7.8,9-9.1c2.1-0.6,4.2-0.9,6.2-1.7
		c1.2-0.5,4.7-3,2.1-3.9c-1.5-0.5-3.2-0.1-4.6-0.8c-1.1-0.5-1.8-1.5-1.6-2.8c0.3-2.6,2.7-5.5,4-7.8c1-1.8,2.1-3.5,3.1-5.3
		c0.5-1,0.8-1.9,0.8-2.7c0-1.8-1-2.7-3.1-2.7h-27.1c-1.5,0-3.1,0.5-4.8,1.6c-1.7,1.1-3,2.3-3.8,3.8l-47.2,81.8
		c-2.3-7.9-3.5-16.2-3.5-24.8c0-48.9,39.8-88.6,88.6-88.6c38.4,0,71.2,24.5,83.4,58.8h-32.5c-2.2,0-3.3-0.9-3.3-2.6
		c0-0.7,0.2-1.4,0.6-2.1c0.9-1.6,1.4-3.3,1.4-5.2c0-1.3-0.2-2.3-0.7-3.1l-15-25.6c-0.4-0.7-0.9-1-1.6-1c-1.3,0-2.5,1-3.6,2.9
		l-52.5,90.9c-0.5,1-0.8,1.9-0.8,2.7c0,1.8,1,2.7,3.1,2.7h76c1.5,0,3.1-0.5,4.8-1.6c1.7-1.1,3-2.3,3.8-3.8l24-41.5
		c1.1,5.5,1.7,11.3,1.7,17.1C238.6,198.9,198.9,238.6,150,238.6z M200.9,128.3c0,0.8-0.3,1.7-0.8,2.7l-21.9,38
		c-0.9,1.5-2.2,2.8-3.8,3.8c-1.7,1.1-3.2,1.6-4.7,1.6c-2.1,0-3.2-0.9-3.2-2.7c0-0.8,0.3-1.7,0.9-2.7l21.9-38
		c0.8-1.5,2.1-2.8,3.8-3.9c1.7-1.1,3.3-1.6,4.7-1.6C199.9,125.6,200.9,126.5,200.9,128.3z"
    />
  </svg>
);
