import React from "react";

export default function StyledBorder({ children, isAlternate, classList }) {
  return (
    <div
      className={`styledborder ${
        Array.isArray(classList) ? classList.join(" ") : ""
      }`}
    >
      <div className="styledborder__container">
        {isAlternate && (
          <div className="styledborder__header">
            <div className="styledborder__header__btns">
              {[...Array(3)].map((_, i) => (
                <span key={i} className={`btn`}>
                  <span className={`btn__stack btn__stack__${i + 1}`}></span>
                </span>
              ))}
            </div>
          </div>
        )}

        <div className="styledborder__body">{children}</div>
      </div>
    </div>
  );
}
