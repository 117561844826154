import React, { useState, useEffect } from "react";
import GridBGOverlay from "../components/GridBGOverlay";
import StyledBorder from "../components/StyledBorder";
import {
  Instagram,
  Twitter,
  Youtube,
  Spotify,
  Mail,
  TikTok,
  Location,
  TBCoin,
} from "../icons";
import { Link } from "react-router-dom";
import Preloader from "../components/Preloader";
import ScrollSpyAnimate from "../components/ScrollSpyAnimate";
import { Icon } from "semantic-ui-react";
import StyledLink from "../components/StyledLink";

export default function Home() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [imgCounter, setImgCounter] = useState(0);
  const totalImages = 5;

  const socials = [
    {
      icon: Instagram,
      path: "https://www.instagram.com/jrezmp3/",
    },
    {
      icon: TikTok,
      path: "https://www.tiktok.com/@jrezmp3",
    },
    {
      icon: Twitter,
      path: "https://twitter.com/jrezmp3",
    },
    {
      icon: Youtube,
      path: "https://www.youtube.com/c/JerickReyes",
    },
    {
      icon: Spotify,
      path: "https://open.spotify.com/artist/6OtXaptqHbEiHfHaykid5y?si=rakTpyEXRli7dM_fMEIWoQ",
    },
    {
      icon: Mail,
      path: "mailto:jrezmp3@outlook.com",
    },
  ];

  const links = [
    {
      thumbnail: "/images/dont-take.jpg",
      heading: "Don't Take",
      description: "New Single By J.Rez featuring Cluelet",
      tag: "Stream New Single",
      path: "https://open.spotify.com/track/1YZPLtLCJiuXpAnBbNUwxK?si=9d1363a0ec264b3a",
      type: "external",
    },
    {
      thumbnail: "/images/woah-thumb.jpg",
      heading: "NEW: WOAH",
      description:
        "Check out exclusive photo galleries, the official music video, and more!",
      tag: "Experience WOAH",
      path: "/woah",
      type: "internal",
      disabled: false,
      icon: TBCoin,
    },
    {
      thumbnail: "/images/rollin.jpg",
      heading: "ROLLIN'",
      description: "Prod. by Only Human Productions.",
      tag: "Stream New Single",
      path: "https://open.spotify.com/album/6gYQNhKMRV0tDa61U3YYPh?si=1tOd1GLdTguW16eBS4newg&nd=1",
      type: "external",
    },
    {
      thumbnail: "/images/tito-bong.png",
      heading: "TITO BONG",
      description: "Dir. by Josh Aries, Prod. by Only Human Productions.",
      tag: "Watch Music Video",
      path: "https://www.youtube.com/watch?v=xHEiL7ExSaA",
      type: "external",
    },
    {
      thumbnail: "/images/summers-over.jpg",
      heading: "SUMMER'S OVER",
      description: "Dir. by Kyle Murdoch, Prod. by Only Human Productions.",
      tag: "Watch Music Video",
      path: "https://www.youtube.com/watch?v=RVESdnPNZZs",
      type: "external",
    },
  ];

  const incrementCounter = () => {
    let newCount = imgCounter + 1;
    setImgCounter(newCount);
  };

  useEffect(() => {
    if (imgCounter >= totalImages) setIsLoaded(true);
  }, [imgCounter]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <GridBGOverlay from={"var(--highlight-3)"} to={"var(--highlight-4)"} />
      <main className="main__home">
        <div className="profile">
          <div className="profile__head">
            <ScrollSpyAnimate
              dependencies={[isLoaded]}
              classList={["top", "profile__nametag__shadow"]}
              baseDelaySecs={0.8}
            >
              <StyledBorder>
                <div>
                  <h2>J.REZ</h2>
                  <p>Artist, Mental Health Advocate</p>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded]}
              classList={["bottom"]}
              baseDelaySecs={0.5}
            >
              <StyledBorder isAlternate={true} classList={["profile__picture"]}>
                <div className="img">
                  <img
                    onLoad={() => incrementCounter()}
                    src="/images/jrez-profile.jpg"
                    alt="profile"
                  />
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded]}
              classList={["right"]}
              baseDelaySecs={0.6}
            >
              <StyledBorder classList={["profile__socials"]}>
                <div className="profile__socials__column">
                  {socials.map((s, i) => (
                    <a key={i} href={s.path} target="_blank" rel="noreferrer">
                      <s.icon />
                    </a>
                  ))}
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded]}
              classList={["top", "profile__nametag"]}
              baseDelaySecs={0.7}
            >
              <StyledBorder>
                <div className="profile__nametag__container">
                  <h2>J.REZ</h2>
                  <p>Artist, Mental Health Advocate</p>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>
          </div>

          <div className="profile__body">
            <div className="profile__bio">
              <ScrollSpyAnimate
                dependencies={[isLoaded]}
                classList={["top", "bio__location"]}
                baseDelaySecs={0.9}
              >
                <StyledBorder>
                  <span>
                    <Location />{" "}
                    <p>
                      Winnipeg <Icon name="arrows alternate horizontal" />
                      Vancouver
                    </p>{" "}
                  </span>
                </StyledBorder>
              </ScrollSpyAnimate>
              <ScrollSpyAnimate
                dependencies={[isLoaded]}
                classList={["bottom", "bio"]}
                baseDelaySecs={0.6}
              >
                <StyledBorder isAlternate={true}>
                  <div className="profile__bio__container">
                    <h3>Profile</h3>
                    <p>
                      I am a rapper and songwriter from Burnaby, Canada. I am a
                      member of the local music scene and my life goal is to
                      spread genuine positive energy within my community and
                      into the universe, while staying true to myself and my
                      artistry. 💜
                    </p>
                  </div>
                </StyledBorder>
              </ScrollSpyAnimate>
            </div>
          </div>
        </div>

        <div className="links">
          <ScrollSpyAnimate
            dependencies={[isLoaded]}
            classList={["right"]}
            baseDelaySecs={0.8}
          >
            <h2 className="links__title" style={{ width: "100%" }}>
              Featured
            </h2>
          </ScrollSpyAnimate>
          <ul>
            {links.map((l, i) => (
              <li className={l.disabled ? "disabled" : "enabled"} key={i}>
                {l.type === "external" && !l?.disabled && (
                  <a href={l.path} target="_blank" rel="noreferrer">
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded]}
                      imgLoadCb={incrementCounter}
                      disabled={l?.disabled}
                    />
                  </a>
                )}

                {l.type === "internal" && !l?.disabled && (
                  <Link to={l.path}>
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded]}
                      imgLoadCb={incrementCounter}
                      disabled={l?.disabled}
                    />
                  </Link>
                )}

                {(l.type === "button" || l?.disabled) && (
                  <button onClick={l?.action ? () => l.action() : () => null}>
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded]}
                      imgLoadCb={incrementCounter}
                      disabled={l?.disabled}
                    />
                  </button>
                )}
              </li>
            ))}
            <li>
              <ScrollSpyAnimate
                dependencies={[isLoaded]}
                classList={["bottom"]}
                baseDelaySecs={0.6}
              >
                <h2
                  style={{
                    textShadow: "var(--text-shadow)",
                    fontSize: 14,
                    letterSpacing: "0.1em",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--elevation-1)",
                      display: "block",
                    }}
                    href="https://tropicbounce.ca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Web-design by Tropic Bounce
                    <sup
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontSize: 9,
                        transform: "translateX(4px)",
                      }}
                    >
                      &nbsp; &reg;
                    </sup>
                  </a>
                </h2>
              </ScrollSpyAnimate>
            </li>
          </ul>
        </div>
      </main>

      <Preloader dependencies={[isLoaded]} />
    </>
  );
}
