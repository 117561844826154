import React, { useState, useEffect, useRef } from "react";
import GridBGOverlay from "../GridBGOverlay";
import StyledBorder from "../StyledBorder";
import { Icon } from "semantic-ui-react";
import Preloader from "../Preloader";
import ScrollSpyAnimate from "../ScrollSpyAnimate";

export default function Gallery({
  images,
  gTo,
  gFrom,
  title,
  setter,
  isActive,
}) {
  const [active, setActive] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [imgLoadCount, setImageLoadCount] = useState(0);

  const bodyRef = useRef(document.body);

  const incrementCounter = () => {
    let imgLoadCount_ = imgLoadCount + 1;
    setImageLoadCount(imgLoadCount_);
  };

  const delaySwitch = () => {
    setTimeout(() => {
      setActive(isActive);
    }, 1500);
  };

  useEffect(() => {
    if (isActive === true) {
      setActive(isActive);
      if (bodyRef?.current) bodyRef.current.style.overflow = "hidden";
    } else {
      delaySwitch();
      if (bodyRef?.current) bodyRef.current.style.overflow = "scroll";
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (Array.isArray(images)) {
      if (imgLoadCount >= images.length) setImagesLoaded(true);
    }
  }, [imgLoadCount]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!active) return null;
  return (
    <div className={`gallery ${isActive ? "active" : "inactive"}`}>
      <GridBGOverlay to={gTo} from={gFrom} />
      <div className="gallery__header">
        <div className="gallery__header__wrap">
          <div>
            <StyledBorder>
              <h2>{title}</h2>
            </StyledBorder>
          </div>
          <div>
            <StyledBorder classList={["nopadding"]}>
              <button onClick={() => setter(false)}>
                <Icon name="x" />
              </button>
            </StyledBorder>
          </div>
        </div>
      </div>
      <div className="gallery__body__container">
        <div className="gallery__body">
          {Array.isArray(images) &&
            images.map((img, i) => (
              <div key={i} className="gallery__item__container">
                <div className="gallery__item">
                  <ScrollSpyAnimate
                    dependencies={[imagesLoaded]}
                    classList={["bottom"]}
                    baseDelaySecs={0.5}
                    disableScrollSpy={true}
                  >
                    <StyledBorder isAlternate={true}>
                      <a
                        href={`${img.path}`}
                        target="_blank"
                        rel="noreferrer"
                        className="gallery__image"
                      >
                        <div className="gallery__image__container">
                          <img
                            onLoad={() => incrementCounter()}
                            onError={() => incrementCounter()}
                            src={img.path}
                            alt={img.path}
                          />
                        </div>
                        {img?.caption && (
                          <div className="gallery__image__caption">
                            <p>{img.caption}</p>
                          </div>
                        )}
                      </a>
                    </StyledBorder>
                  </ScrollSpyAnimate>
                </div>
              </div>
            ))}
        </div>
      </div>

      <Preloader dependencies={[imagesLoaded, active]} />
    </div>
  );
}
