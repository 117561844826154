import React from "react";
import StyledBorder from "../StyledBorder";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default function PageControls({ next, prev }) {
  return (
    <StyledBorder classList={["page__controls"]}>
      <div className="page__controls__actions">
        {prev?.type === "external" ? (
          <a href={prev.path} to={prev?.path}>
            <Icon name="long arrow alternate left" /> Back
          </a>
        ) : (
          <Link to={prev?.path}>
            <Icon name="long arrow alternate left" /> Back
          </Link>
        )}

        {/* <span></span>
        {next?.type === "external" ? (
          <a href={next.path} to={next?.path}>
            <Icon name="long arrow alternate right" />
          </a>
        ) : (
          <Link to={next?.path}>
            <Icon name="long arrow alternate right" />
          </Link>
        )} */}
      </div>
    </StyledBorder>
  );
}
