import React, { useState, useEffect, useRef } from "react";
import GridBGOverlay from "../../components/GridBGOverlay";
import StyledBorder from "../../components/StyledBorder";
import Preloader from "../../components/Preloader";
import { Icon } from "semantic-ui-react";
import { AppleMusic, Spotify } from "../../icons";
import { Howl } from "howler";
import PageControls from "../../components/PageControls";
import ModalWall from "../../components/ModalWall";
import useLocalStorage from "../../hooks/useLocalStorage";
import StyledLink from "../../components/StyledLink";
import { Link } from "react-router-dom";
import Gallery from "../../components/Gallery";
import ScrollSpyAnimate from "../../components/ScrollSpyAnimate";
import moment from "moment";

export default function Woah() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [soundLoaded, setSoundLoaded] = useState(false);
  const [imgCounter, setImgCounter] = useState(0);
  const [soundState, setSoundState] = useState(null);
  const [soundDuration, setSoundDuration] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const [modalActive, setModalActive] = useState(true);
  const [hitCounter, setHitCounter] = useLocalStorage("hitCounter", 0);
  const [btsGalleryActive, setBtsGalleryActive] = useState(false);
  const [yngnGalleryActive, setYngnGalleryActive] = useState(false);

  let timer = useRef(null);
  let maxHits = 2;

  const onSoundLoad = () => {
    setSoundDuration(sound.current.duration());
    if (soundLoaded !== true) setSoundLoaded(true);
  };

  let sound = useRef(
    new Howl({
      src: ["/audio/woah-sample-v3.mp3"],
      onload: onSoundLoad,
    })
  );

  const links = [
    {
      thumbnail: "/images/woah/woah-visualizer2.gif",
      heading: "Official Music Video",
      description: "Dir. by Trevor Wong, Prod. by Tropic Bounce TV.",
      tag: "Watch Music Video",
      path: "https://youtu.be/oHDX2cvzSBo",
      type: "external",
      unlock: checkUnlock("2022-04-15T19:00:00-07:00"),
      unlockDescription:
        "Stay tuned for the official release of the WOAH music video on April 15th.",
    },
    {
      thumbnail: "/images/woah/bts-video-thumb.gif",
      heading: "BTS Coverage",
      description:
        "Behind the scenes coverage recorded during the production of WOAH's music video.",
      tag: "Watch on Youtube",
      path: "https://youtu.be/ZEhmoWpPpt4",
      type: "external",
      unlock: checkUnlock("2022-04-04T18:00:00-07:00"),
      unlockDescription:
        "Behind the scenes coverage dropping on April 4th at 6PM PST.",
    },
    {
      thumbnail: "/images/woah/bts-gallery-thumb-v2.gif",
      heading: "BTS Gallery",
      description:
        "Behind the scenes moments captured during the production of WOAH's music video.",
      tag: "See Photos",
      path: "/song/woah",
      type: "button",
      action: () => {
        setBtsGalleryActive(!btsGalleryActive);
        if (sound.current && soundState === "play") sound.current.pause();
      },
    },
    {
      thumbnail: "/images/woah/shotsbyyngn-thumb-v2.gif",
      heading: "SHOTS BY YNGN",
      description:
        "Exclusive WOAH portraits captured, curated, and edited by IG:@shotbyyngn.",
      tag: "See Photos",
      path: "/song/woah",
      type: "button",
      action: () => {
        setYngnGalleryActive(!yngnGalleryActive);
        if (sound.current && soundState === "play") sound.current.pause();
      },
    },
  ];

  const discoPrompts = [
    {
      icon: AppleMusic,
      path: "https://music.apple.com/us/album/woah-single/1613185013",
    },
    {
      icon: Spotify,
      path: "https://open.spotify.com/track/5TPe2Gk6W65iMDKgB7aSZk?si=357e6dc2f2324f9d",
    },
  ];

  const totalImages = 1;

  const disableModal = () => {
    let newHitCounter = hitCounter + 1;
    setHitCounter(newHitCounter);
    setModalActive(false);
    if (sound.current && soundLoaded && soundState !== "play")
      sound.current.play();
  };

  const incrementCounter = () => {
    let newCount = imgCounter + 1;
    setImgCounter(newCount);
  };

  const playPauseSound = () => {
    if (!sound?.current) return;

    if (soundState !== "play") {
      sound.current.play();
    } else {
      sound.current.pause();
    }
  };

  const skip = ({ direction, time }) => {
    if (!sound?.current) return;
    if (soundState !== "play") return;

    let currentDur = sound.current._sounds[0]._seek,
      skipDur = direction === "forward" ? currentDur + time : currentDur - time,
      totalDur = sound.current.duration();

    if (direction === "forward") if (skipDur > totalDur) return;
    if (direction === "backward") if (skipDur < 0) return;

    sound.current.seek(skipDur);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (imgCounter >= totalImages) setIsLoaded(true);
  }, [imgCounter]);

  useEffect(() => {
    if (sound.current) {
      sound.current.on("play", () => {
        setSoundDuration(sound.current.duration());
        setSoundState("play");

        timer.current = setInterval(() => {
          setCurrentDuration(sound.current.seek());
        }, 500);
      });

      sound.current.on("stop", () => {
        if (timer?.current) clearInterval(timer.current);
        setSoundState("stop");
      });

      sound.current.on("end", () => {
        if (timer?.current) clearInterval(timer.current);
        setSoundState("end");
      });

      sound.current.on("pause", () => {
        if (timer?.current) clearInterval(timer.current);
        setSoundState("pause");
      });
    }
    // eslint-disable-next-line
  }, [sound, undefined]);

  useEffect(() => {
    if (hitCounter >= maxHits) setModalActive(false);
    return () => {
      // eslint-disable-next-line
      if (sound.current) sound.current.stop();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GridBGOverlay from={"var(--highlight-3)"} to={"var(--highlight-1)"} />
      <main className="main__woah">
        <ScrollSpyAnimate
          dependencies={[isLoaded, !modalActive, soundLoaded]}
          classList={["bottom"]}
          baseDelaySecs={0.5}
        >
          <PageControls
            prev={{
              type: "internal",
              path: "/",
            }}
          />
        </ScrollSpyAnimate>
        <div className="profile">
          <div className="profile__head">
            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={[
                "top",
                "profile__nametag",
                "profile__nametag__shadow",
                "player__controls",
              ]}
              baseDelaySecs={0.7}
            >
              <StyledBorder>
                <div className="player__controls__actions">
                  <button>
                    <Icon name="step backward" />
                  </button>
                  <button>
                    <Icon name="play" />
                  </button>
                  <button>
                    <Icon name="step forward" />
                  </button>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={["bottom", "profile__picture", "audio__player"]}
              baseDelaySecs={0.4}
            >
              <StyledBorder isAlternate={true}>
                <div className="img">
                  <div
                    className={`promo ${
                      soundState === "end" ? "active" : "inactive"
                    }`}
                  >
                    <div className="promo__container">
                      <h2>Full Song</h2>
                      <a
                        href="https://open.spotify.com/track/5TPe2Gk6W65iMDKgB7aSZk?si=357e6dc2f2324f9d"
                        target="_blank"
                        rel="noreferrer"
                      >
                        On Spotify
                        <Icon name="external square" />
                      </a>
                    </div>
                  </div>
                  <img
                    onLoad={() => incrementCounter()}
                    src="/images/woah/woah-visualizer.gif"
                    alt="profile"
                  />
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={["left", "profile__nametag", "songtag"]}
              baseDelaySecs={0.8}
            >
              <StyledBorder>
                <div>
                  <h2>WOAH</h2>
                  <p>By J.Rez, Produced by Fynnesse</p>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={["right", "profile__socials"]}
              baseDelaySecs={0.8}
            >
              <StyledBorder>
                <div className="profile__socials__column discography">
                  <div className="discography__icons">
                    {discoPrompts.map((s, i) => (
                      <a key={i} href={s.path} target="_blank" rel="noreferrer">
                        <s.icon />
                      </a>
                    ))}
                  </div>
                  <div className="discography__prompt">
                    <h2>LISTEN NOW!</h2>
                    <p>
                      “WOAH” OUT ON ALL PLATFORMS <br /> APRIL 15TH
                    </p>
                  </div>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={["top", "profile__nametag", "player__controls"]}
              baseDelaySecs={0.7}
            >
              <StyledBorder>
                <div className="player__controls__actions">
                  <button
                    onClick={() => skip({ direction: "backward", time: 1 })}
                  >
                    <Icon name="fast backward" />
                  </button>
                  <button onClick={() => playPauseSound()}>
                    <Icon name={soundState === "play" ? "pause" : "play"} />
                  </button>
                  <button
                    onClick={() => skip({ direction: "forward", time: 1 })}
                  >
                    <Icon name="fast forward" />
                  </button>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>

            <ScrollSpyAnimate
              dependencies={[isLoaded, !modalActive, soundLoaded]}
              classList={["left", "audio__timeline__wrap"]}
              baseDelaySecs={0.6}
            >
              <StyledBorder classList={["audio__timeline"]}>
                <div className="audio__timeline__cont">
                  <span>{formatSeconds(currentDuration)}</span>
                  <div className="audio__timeline__progress">
                    <div
                      className="audio__timeline__bar"
                      style={{
                        width: `${(currentDuration / soundDuration) * 100}%`,
                      }}
                    ></div>
                  </div>
                  <span>{formatSeconds(soundDuration)}</span>
                </div>
              </StyledBorder>
            </ScrollSpyAnimate>
          </div>

          <div className="profile__body"></div>
        </div>

        <div className="links">
          <ul>
            {links.map((l, i) => (
              <li
                className={l.disabled || l?.unlock ? "disabled" : "enabled"}
                key={i}
              >
                {l.type === "external" && (
                  <a
                    href={l.path}
                    target="_blank"
                    onClick={
                      l?.unlock || l.disabled
                        ? (e) => e.preventDefault()
                        : () => null
                    }
                    rel="noreferrer"
                  >
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded, !modalActive, soundLoaded]}
                      imgLoadCb={incrementCounter}
                    />
                  </a>
                )}

                {l.type === "internal" && !l.disabled && (
                  <Link to={l.path}>
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded, !modalActive, soundLoaded]}
                      imgLoadCb={incrementCounter}
                    />
                  </Link>
                )}

                {l.type === "button" && (
                  <button
                    onClick={
                      l.disabled
                        ? () => console.log("disabled")
                        : () => l.action()
                    }
                  >
                    <StyledLink
                      l={l}
                      i={i}
                      dependencies={[isLoaded, !modalActive, soundLoaded]}
                      imgLoadCb={incrementCounter}
                    />
                  </button>
                )}
              </li>
            ))}
            <li>
              <ScrollSpyAnimate
                dependencies={[isLoaded, !modalActive, soundLoaded]}
                classList={["bottom"]}
                baseDelaySecs={0.6}
              >
                <h2
                  style={{
                    textShadow: "var(--text-shadow)",
                    fontSize: 14,
                    letterSpacing: "0.1em",
                  }}
                >
                  <a
                    style={{
                      textAlign: "center",
                      color: "var(--elevation-1)",
                      display: "block",
                    }}
                    href="https://tropicbounce.ca"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Produced by Tropic Bounce
                    <sup
                      style={{
                        fontFamily: "sans-serif",
                        fontStyle: "normal",
                        fontSize: 9,
                        transform: "translateX(4px)",
                      }}
                    >
                      &nbsp; &reg;
                    </sup>
                  </a>
                </h2>
              </ScrollSpyAnimate>
            </li>
            <li>
              <ScrollSpyAnimate
                dependencies={[isLoaded, !modalActive, soundLoaded]}
                classList={["bottom"]}
                baseDelaySecs={0.6}
              >
                <StyledBorder classList={["coming__soon"]}>
                  <h2>More coming soon</h2>
                  <p>Check back periodically for more exclusive content!</p>
                </StyledBorder>
              </ScrollSpyAnimate>
            </li>
          </ul>
        </div>
      </main>
      <Gallery
        isActive={btsGalleryActive}
        setter={() => setBtsGalleryActive(false)}
        gFrom={"var(--highlight-3)"}
        gTo={"var(--highlight-1)"}
        title={"Behind the scenes"}
        images={btsgallery}
      />

      <Gallery
        isActive={yngnGalleryActive}
        setter={() => setYngnGalleryActive(false)}
        gFrom={"var(--highlight-3)"}
        gTo={"var(--highlight-1)"}
        title={"Shots By Yngn"}
        images={yngngallery}
      />
      {hitCounter < maxHits ? (
        <ModalWall
          isActive={modalActive}
          actionCb={disableModal}
          cta={"Continue to Woah"}
        />
      ) : null}
      <Preloader dependencies={[isLoaded, soundLoaded]} />
    </>
  );
}

function formatSeconds(s) {
  return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + Math.floor(s);
}

function checkUnlock(dateString) {
  if (moment(dateString).isAfter(moment(new Date()))) return dateString;
  return null;
}

const btsgallery = [
  {
    caption: "",
    path: "/images/woah/btsgallery/image-1.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-2.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-3.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-4.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-5.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-6.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-7.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-8.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-9.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-10.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-11.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-12.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-13.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-14.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-15.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-16.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-17.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-18.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-19.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-20.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-21.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-22.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-23.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-24.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-25.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-26.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-27.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-28.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-29.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-30.jpg",
  },
  {
    caption: "",
    path: "/images/woah/btsgallery/image-31.jpg",
  },
];

const yngngallery = [
  {
    caption: "",
    path: "/images/woah/yngn/image-1.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-2.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-3.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-4.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-5.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-6.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-7.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-8.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-9.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-10.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-11.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-12.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-13.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-14.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-15.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-16.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-17.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-18.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-19.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-20.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-21.jpg",
  },
  {
    caption: "",
    path: "/images/woah/yngn/image-22.jpg",
  },
];
